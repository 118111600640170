import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/sirhely',
    name: 'Sirhely',
    component: () => import( '../views/Sirhely'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jogosultak',
    name: 'Jogosultak',
    component: () => import( '../views/Jogosultak.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jogutodok',
    name: 'Jogutodok',
    component: () => import( '../views/Jogtudok.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/elhunytak',
    name: 'Elhunytak',
    component: () => import('../views/Elhunytak.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/belepes',
    name: 'Belepes',
    component: () => import( '../views/Login.vue'),
  },
  // {
  //   path: '/import',
  //   name: 'Import',
  //   component: () => import( '../views/Import.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // }
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/belepes')
  } else {
    next()
  }
})

export default router
