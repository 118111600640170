<template>
  <div class="bg-light" v-if="isLoggedIn">
    <b-nav class="d-flex justify-content-between">
      <div class="d-flex">
        <b-nav-item to="/" :active="$route.path === '/'" :active-class="$route.path === '/' ? 'border' :''" >
          <span>Főoldal</span>
        </b-nav-item>
        <b-nav-item to="/sirhely" :active="$route.path === '/sirhely'" :active-class="$route.path === '/sirhely' ? 'border' :''" >
          <span>Sirhelyek</span>
        </b-nav-item>
        <b-nav-item  to="/jogosultak" :active="$route.path === '/jogosultak'" active-class="border">
          <span class="">Jogosultak</span>
        </b-nav-item>
        <b-nav-item  to="/jogutodok" :active="$route.path === '/jogtudok'" active-class="border">
          <span class="">Jogutodok</span>
        </b-nav-item>
        <b-nav-item  to="/elhunytak" :active="$route.path === '/elhunytak'" active-class="border">
          <span class="">Elhunytak</span>
        </b-nav-item>
      </div>

      <b-nav-item  active-class="border" @click="logout">
        <span class="">Kilépés</span>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "Header",
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/belepes')
          })
      this.$toasted.show('Sikeresen kijelentkezett.', {
        duration: 2000,
      })
    }
  },
}
</script>

<style >
  .toasted-container.top-right {
    top: 5% !important;
    right: 21.2% !important;
  }
</style>
