<template>
  <div class="home" :style="`background-image: url(${img})`">
    <b-container :fluid="true">
      <b-row class="py-5">
        <b-col md="3">
          <router-link to="/sirhely" class="d-flex align-items-center justify-content-center box border border-dark bg-white rounded">
              <h3 class="text-center">
                Sirhelyek
              </h3>
          </router-link>
        </b-col>
        <b-col md="3">
          <router-link to="/jogosultak" class="d-flex align-items-center justify-content-center box border border-dark bg-white rounded">
            <h3 class="text-center">
              Jogosultak
            </h3>
          </router-link>
        </b-col>
        <b-col md="3">
          <router-link to="/jogutodok" class="d-flex align-items-center justify-content-center box border border-dark bg-white rounded">
            <h3 class="text-center">
              Jogutodok
            </h3>
          </router-link>
        </b-col>
        <b-col md="3">
          <router-link to="/elhunytak" class="d-flex align-items-center justify-content-center box border border-dark bg-white rounded">
            <h3 class="text-center">
              Elhunytak
            </h3>
          </router-link>
        </b-col>
      </b-row>
      <b-card class="bg-white">
       Kezedre bízom lelkemet, te váltasz meg engem, Uram, igaz Isten!
        <strong> Zsolt 31,6</strong>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import img from '../../data/kep.jpeg'
export default {
  name: 'Home',
  components: {
  },
  data(){
    return{
      img
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.bg-white{
  background-color: rgba(255,255,255,0.85) !important;
}
    .home {
      height: 75vh;
    }
    .box{
      min-height: 120px
    }

</style>
