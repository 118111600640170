<template>
  <b-container>
    <b-row class="position-relative">
      <b-col md="12" class="mt-4">
        <img :src="logo" width="300" alt="Logo"/>
        <b-card id="app" class="border-dark" body-class="p-0">
          <div v-if="loading" class="d-flex justify-content-center align-items-center h-100" style="min-height: 60vh">
            <img :src="spinner" alt="Spinner" height="200"/>
          </div>
            <template v-if="isLoggedIn" slot="header"  class="border-dark" >
              <Header/>
            </template>
            <div v-if="!loading">
              <router-view/>
            </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss">
  @import "./assets/scss/style";
  .card-header{
    border-color: $dark;
  }
  .ag-filter-apply-panel{
    .ag-standard-button.ag-filter-apply-panel-button{
      border-color: black !important;
      color: black!important;
    }
  }

</style>

<script>
  import Header from "./components/Header/Header";
  import {mapGetters} from "vuex";
  import logo from '../assets/images/logo.png'
  import spinner from '../assets/images/spinner.gif'
  import axios from "axios";



  document.title = 'Temeto'
  export default {
    components: {Header},
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/belepes')
            })
      },
      async getAllData(){
        const datArr = [
          {key:'elhunytak',url:'/elhunytaks?_limit=-1'},
          {key:'sirhely',url:'/sirhelies?_limit=-1'},
          {key: 'jogosultak',url: '/jogosultaks?_limit=-1'},
          {key: 'orokosok',url: '/orokosoks?_limit=-1'}]
        const index = 0
        await this.getOneData(datArr,index)
      },
      async getOneData(dataArr,index){
        const criteria = dataArr[index]
        if(criteria){
          const { data } = await this.axios.get(criteria.url)
          window[criteria.key] = data;
          const newIndex = index +1
          await this.getOneData(dataArr,newIndex)
        }else{
          this.loading = false
        }
      }
    },
    data(){
      return{
        logo,
        loading: true,
        spinner
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn'])
    },
    mounted() {
      console.log('Mounted app')
      axios.interceptors.response.use((response) => response, (error) => {
        // whatever you want to do with the error
        this.$toasted.show('Hiba történt próbálkozzon késöbb.', {
          type: 'error',
          duration: 2000,
        })
        this.$store.dispatch('logout');
        this.$router.push("/belepes")
        throw error;
      });
      this.getAllData();
    },
    created: function () {
      const errorComposer = (error) => {
        return () => {
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 401) {
            localStorage.clear()
          }
        }
      }

      axios.interceptors.response.use(undefined, function (error) {
        error.handleGlobally = errorComposer(error);

        return Promise.reject(error);
      })


      document.title ='Református temető adatbázis';
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          if (err.status === 401) {
            this.$store.dispatch(this.logout)
          }
          throw err;
        });
      });
    }
  }
</script>

