import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import { BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {API_URL} from "../config/dev";
import Toasted from 'vue-toasted';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueHtml2pdf from 'vue-html2pdf'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueHtml2pdf)
Vue.use(VueClipboard)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Toasted)
axios.defaults.baseURL = API_URL;
Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(VueAxios,axios)


new Vue({
  render: h => h(App),
  store,
  router,
  components: { App }
}).$mount('#app')
